import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TextFieldContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 24px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
  position: absolute;
  right: 20px;
  top: 30px;
  transform: translateY(-50%);
`;

export const CheckBoxLabel = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[30]};
  cursor: inherit;
`;

export const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs1};
`;

export const Form = styled.div`
  padding: ${({ theme }) => `${theme.spacings.xs} ${theme.spacings.s}`};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacings.xs1};
`;
