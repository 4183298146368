import { useState } from 'react';
import { useCompanySelfSignupMutation } from './use-company-self-signup';
import { useCompanySignupMutation } from './use-company-signup';
import { useUpdateDealStage } from './use-update-deal-stage';
import { ErrorBoundary } from '@/utils';
import { setInSS } from '@flash-tecnologia/hros-web-utility';
import { useSearchParams } from 'react-router-dom';

export const useSignup = () => {
  const { companySignUp, isLoading: isSignupLoading } =
    useCompanySignupMutation();
  const { companySelfSignUp, isLoading: isSelfSignupLoading } =
    useCompanySelfSignupMutation();
  const { updateDealStage } = useUpdateDealStage();
  const [searchParams] = useSearchParams();
  const selfSignupToken = searchParams.get('self_signup_token');

  const [isLoading, setIsLoading] = useState(false);

  const signup = async ({
    company,
    admin,
    dealId,
    leadId,
  }: SignupParams): Promise<SignupResult | undefined> => {
    let companyId: string;
    let employeeId: string;
    let contractId: string | undefined;

    try {
      setIsLoading(true);

      if (!!dealId) {
        const result = await companySignUp({
          company,
          admin,
          dealId,
          leadId,
        });
        employeeId = result!.value.employeeId;
        companyId = result!.value.companyId;
      } else {
        const result = await companySelfSignUp({
          company,
          admin,
          selfSignupToken,
        });
        companyId = result!.company.value.companyId;
        employeeId = result!.company.value.employeeId;
        contractId = result!.contractIds[0];

        if (selfSignupToken) {
          await updateDealStage({
            trackingId: selfSignupToken,
            stage: 'signup-success',
          });
        }
      }

      setInSS({
        key: 'temporaryCompanyInfos',
        value: {
          ...company,
          employeeEmail: admin.email,
          employeeId,
          companyId,
          contractId,
        },
      });

      return {
        companyId,
        employeeId,
        contractId,
      };
    } catch (error) {
      ErrorBoundary.captureException(error as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    signup,
    isLoading: isLoading || isSelfSignupLoading || isSignupLoading,
  };
};

export type SignupParams = {
  company: {
    registrationNumber: string;
    legalName: string;
    name: string;
    employeesQuantity?: string;
    address: {
      zipCode: string;
      street: string;
      number: string;
      complement: string;
      district: string;
      city: string;
      state: string;
    };
  };
  admin: {
    email: string;
    name: string;
    documentNumber: string;
    phone: string;
  };
  dealId?: string;
  leadId?: string;
  isSelfSignup?: boolean;
  selfSignupToken?: string;
};

type SignupResult = {
  companyId: string;
  employeeId: string;
  contractId?: string;
};
