import { useEffect, useState } from 'react';
import {
  Icons,
  Checkbox,
  Typography,
  CircularProgress,
} from '@flash-tecnologia/hros-web-ui-v2';

import { useSearchParams } from 'react-router-dom';
import { ContractErrorModal } from './components/ContractErrorModal';
import { trackEvent, trackPage } from '../../../../utils/eventTracking';
import { ContractFormValues } from './contract.hooks';
import { GeneralServicesTerm, TermsAndConditionsModal } from '@/components';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { StepContainer } from '../CompanyInfo/company-info.styles';
import {
  ConfirmationContainer,
  Headline,
  LoadingWrapper,
  Hyperlink,
  TextsContainer,
} from './contract.styles';
import { useGetContractSignedUrl } from '@/pages/SignUp/hooks/use-get-contract-signed-url';
import { useCreateContract } from '@/pages/SignUp/hooks/use-create-contract';
import { FormikProps } from 'formik';
import { useSelfSignupStepNavigate } from '../../hooks';

const contractIllustrationImage =
  'https://images.flashapp.com.br/flash-os/signup/contract-page-illustration.png';

type ContractProps = {
  form: FormikProps<ContractFormValues>;
};

export const Contract = ({ form }: ContractProps) => {
  const { navigateToStep } = useSelfSignupStepNavigate();
  const [searchParams] = useSearchParams();
  const { getSignedUrl, isLoading: isLoadingContractUrl } =
    useGetContractSignedUrl();
  const { createContract, isLoading: isLoadingCreateContract } =
    useCreateContract();

  const [contractId, setContractId] = useState(searchParams.get('contractId'));
  const [serviceTermOpen, setServiceTermOpen] = useState(false);
  const [contractOpen, setContractOpen] = useState(false);
  const [contractUrl, setContractUrl] = useState('');
  const [showContractError, setShowContractError] = useState(false);

  const getContractSignedUrl = async (createdContractId?: string) => {
    if (isLoadingContractUrl) return;
    setShowContractError(false);

    try {
      const selfSignupInfo = getFromSS('selfSignupInfo');
      const contract = await getSignedUrl({
        employeeId: selfSignupInfo.employeeId,
        contractId:
          createdContractId || contractId || selfSignupInfo.contractId,
      });

      setContractUrl(contract.url);
    } catch {
      setShowContractError(true);
    }
  };

  const openContract = () => {
    trackEvent('self_signup_contract_open_contract_clicked');
    if (!contractUrl) getContractSignedUrl();
    setContractOpen(true);
  };

  const openTerms = () => {
    trackEvent('self_signup_contract_open_terms_clicked');
    setServiceTermOpen(true);
  };

  const createContractV2 = async () => {
    const selfSignupInfo = getFromSS('selfSignupInfo');
    const contract = await createContract({
      employeeId: selfSignupInfo.employeeId,
      companyId: selfSignupInfo.companyId,
      email: selfSignupInfo.employeeEmail,
    });

    if (!contract.id) return;

    setContractId(contract.id);
    getContractSignedUrl(contract.id);
  };

  useEffect(() => {
    const selfSignupInfo = getFromSS('selfSignupInfo');
    if (!selfSignupInfo) {
      navigateToStep('company-info');
    }
  }, []);

  useEffect(() => {
    trackPage('self_signup_contract_page');

    if (!!contractId) {
      getContractSignedUrl();
    } else {
      createContractV2();
    }
  }, []);

  if (isLoadingCreateContract || !contractId) {
    return (
      <LoadingWrapper>
        <CircularProgress variant="indeterminate" size={80} />
      </LoadingWrapper>
    );
  }

  return (
    <StepContainer>
      <img src={contractIllustrationImage} alt="contract-illustration" />
      <TextsContainer>
        <Headline>
          <Icons name="IconSparkles" color="var(--color-secondary-50)" />
          <Typography.Body3 color="brand.50" weight={700}>
            Pronto!
          </Typography.Body3>
        </Headline>
        <Typography.Headline7 color="neutral.30">
          Agora, é só aceitar os termos e contrato para começar a usar a
          plataforma!
        </Typography.Headline7>
      </TextsContainer>
      <ConfirmationContainer>
        <Checkbox
          name="termsChecked"
          value={form.values.termsChecked}
          onChange={(e) =>
            form.setFieldValue('termsChecked', e.target.checked, true)
          }
        />
        <Typography.Body4 color="neutral.30" weight={600}>
          Li e aceito os{' '}
          <Hyperlink
            onClick={openTerms}
            children="termos e condições gerais de uso"
          />
          <br />e também o{' '}
          <Hyperlink
            onClick={openContract}
            children="contrato de prestação de serviços"
          />{' '}
          da Flash
        </Typography.Body4>
      </ConfirmationContainer>
      <TermsAndConditionsModal
        open={serviceTermOpen}
        onClose={() => setServiceTermOpen(false)}
      />
      <GeneralServicesTerm
        open={contractOpen && !showContractError}
        onClose={() => setContractOpen(false)}
        loading={isLoadingContractUrl}
        url={contractUrl}
        onRetry={getContractSignedUrl}
      />
      <ContractErrorModal
        open={contractOpen && showContractError}
        onClose={() => setContractOpen(false)}
      />
    </StepContainer>
  );
};
