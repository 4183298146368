import { useEffect, useRef, useState } from 'react';
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import {
  Button,
  Divider,
  Icons,
  LinkButton,
  IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Content,
  Footer,
  FooterButtons,
  Header,
  ImageColumn,
  Image,
  MainContainer,
} from './signup.styles';
import {
  FinishLaterModal,
  CompanySignupSuccessModal,
  SignupFinishModal,
} from '@/components';

import LogoFlash from '../../assets/logo_flash.svg';
import { useCompanyInfoForm } from './steps/CompanyInfo/company-info.hooks';
import { useAdminInfoForm } from './steps/AdminInfo/admin-info.hooks';
import { useCommercialConditionsForm } from './steps/ComercialConditions/commercial-conditions.hooks';
import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import {
  AdminInfo,
  ComercialConditions,
  CompanyInfo,
  SignUpErrorPage,
} from './steps';
import { SignupType } from './signup.types';
import { useGetDeal } from './hooks/use-get-deal';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { AddAdmins } from './steps/AddAdmins';
import { useAddAdminsForm } from './steps/AddAdmins/add-admins.hooks';
import { SignupSteps, useSignupStepNavigate } from './hooks/use-step-navigate';
import { formatCompanySignupPhone, trackEvent } from '@/utils';

const bannerUrl =
  'https://images.flashapp.com.br/flash-os/signup/signup_banner.png';

interface SignupProps {
  signupType: SignupType;
}

export const SignUp = ({ signupType }: SignupProps) => {
  const isAddAdminsEnabled = useFlag({
    flagName: 'FLASH_OS_SIGNUP_ADD_ADMINS_FLOW',
  });
  const refToTop = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { navigateToStep } = useSignupStepNavigate();
  const [searchParams] = useSearchParams();
  const { companyId, dealId, leadId, step } = useParams();
  const { getDealData, error } = useGetDeal(searchParams.get('source') ?? '');
  const { authenticatedUser, loading: userLoading } =
    useCognitoAuthenticatedUser();

  const [registrationNumber, setRegistrationNumber] = useState('');
  const [hasBenefitsProduct, setHasBenefitsProduct] = useState(false);
  const [openCompanySignupSuccessModal, setOpenCompanySignupSuccessModal] =
    useState(false);
  const [openSignupFinishModal, setOpenSignupFinishModal] = useState(false);
  const [openFinishLaterModal, setOpenFinishLaterModal] = useState(false);

  const dealIdV2 = searchParams.get('dealId');

  const onFinishLater = () => setOpenFinishLaterModal(true);

  const onBackToCompanyInfo = () => {
    navigateToStep(SignupSteps.CREATE_COMPANY);
  };

  const onCompanySignupStepFinish = (contractId?: string) => {
    if (contractId) {
      navigate({ search: createSearchParams({ contractId }).toString() });
    }

    if (hasBenefitsProduct || signupType === 'self') {
      setOpenCompanySignupSuccessModal(true);
    } else {
      navigateToStep(SignupSteps.ADD_ADMINS, contractId ? { contractId } : {});
    }
  };

  const onAddAdminsStepFinish = () => {
    if (hasBenefitsProduct || signupType === 'self') {
      navigateToStep(SignupSteps.COMMERCIAL_CONDITIONS);
    } else {
      navigate({
        pathname: '/authentication/access-selection',
        search: createSearchParams({ redirectTo: '/home' }).toString(),
      });
    }
  };

  const onCompanySignupSuccessConfirm = () => {
    setOpenCompanySignupSuccessModal(false);

    if (isAddAdminsEnabled) {
      navigateToStep(SignupSteps.ADD_ADMINS);
      return;
    }

    navigateToStep(SignupSteps.COMMERCIAL_CONDITIONS);
  };

  const onModalEndConfirm = () => {
    trackEvent('signup_finish_info_start_clicked');
    setOpenSignupFinishModal(false);

    navigate({
      pathname: '/authentication/access-selection',
      search: createSearchParams({ redirectTo: '/home' }).toString(),
    });
  };

  useEffect(() => {
    const getDeal = async () => {
      if (dealId) {
        const data = await getDealData(
          dealIdV2 ? { dealId: dealIdV2 } : { hubspotId: dealId },
        );
        setRegistrationNumber(data.registrationNumber);
        setHasBenefitsProduct(data.hasBenefitsProduct);
      }
    };

    getDeal();
  }, []);

  useEffect(
    () => refToTop.current?.scrollIntoView({ behavior: 'smooth' }),
    [step],
  );

  const { form: companyInfoForm } = useCompanyInfoForm({
    dealId,
    leadId,
    onSignupSuccess: onCompanySignupStepFinish,
  });

  const { form: adminInfoForm, isLoading: adminInfoLoading } = useAdminInfoForm(
    {
      signupType,
      dealId,
      leadId,
      onSignupSuccess: onCompanySignupStepFinish,
    },
  );

  const { form: addAdminsForm, isLoading: addAdminsLoading } = useAddAdminsForm(
    { onNext: onAddAdminsStepFinish },
  );

  const {
    form: commercialConditionsForm,
    isLoading: commercialConditionsLoading,
  } = useCommercialConditionsForm({
    signupType,
    dealId: dealId!,
    onSuccess: () => setOpenSignupFinishModal(true),
  });

  useEffect(() => {
    if (!authenticatedUser || userLoading) return;
    const phone = authenticatedUser.attributes.phone_number?.slice(3);
    adminInfoForm.setValues({
      email: authenticatedUser.attributes.email,
      name: authenticatedUser.attributes?.name || '',
      documentNumber: authenticatedUser.attributes.preferred_username.replace(
        /\D/g,
        '',
      ),
      phone: phone ?? formatCompanySignupPhone(phone),
    });
  }, [authenticatedUser, userLoading]);

  const getStep = () => {
    switch (step) {
      case 'create-company':
        return {
          component: (
            <CompanyInfo
              form={companyInfoForm}
              dealId={dealId}
              companyId={companyId}
              registrationNumber={registrationNumber}
            />
          ),
          form: companyInfoForm,
          image: bannerUrl,
          index: 1,
        };
      case 'admin-info':
        return {
          component: (
            <AdminInfo
              form={adminInfoForm}
              dealId={dealId}
              loading={userLoading}
            />
          ),
          form: adminInfoForm,
          onBack: onBackToCompanyInfo,
          image: bannerUrl,
          index: 2,
        };
      case 'add-admins':
        return {
          component: (
            <AddAdmins
              form={addAdminsForm}
              dealId={dealId}
              loading={userLoading}
            />
          ),
          form: addAdminsForm,
          onBack: onBackToCompanyInfo,
          image: bannerUrl,
          index: 3,
        };
      case 'commercial-conditions':
        return {
          component: (
            <ComercialConditions
              form={commercialConditionsForm}
              signupType={signupType}
            />
          ),
          form: commercialConditionsForm,
          button: {
            text: 'Concluir contratação',
            icon: 'IconCheck' as IconsProps['name'],
          },
          image: bannerUrl,
          index: 4,
        };
      default:
        return {
          component: <Navigate to="/signup/self/create-company" replace />,
        };
    }
  };

  const stepProps = getStep();
  const isLoading =
    stepProps.form?.isSubmitting ||
    adminInfoLoading ||
    addAdminsLoading ||
    commercialConditionsLoading;

  if (error || step === 'error') {
    return <SignUpErrorPage />;
  }

  return (
    <MainContainer>
      <Header ref={refToTop}>
        <LogoFlash />
      </Header>
      <Content>
        {stepProps.component}
        {!!stepProps.image && (
          <ImageColumn>
            <Image backgroundUrl={stepProps.image} />
          </ImageColumn>
        )}
      </Content>
      <Divider orientation="horizontal" />
      <Footer>
        {signupType === 'hubspot' && (
          <LinkButton
            variant="default"
            onClick={onFinishLater}
            disabled={isLoading}
            style={{ alignSelf: 'auto' }}
          >
            Terminar mais tarde
          </LinkButton>
        )}
        <FooterButtons>
          {stepProps.onBack && (
            <Button
              size="large"
              variant="secondary"
              loading={isLoading}
              style={{ width: '250px' }}
              onClick={stepProps.onBack}
            >
              <Icons name="IconArrowLeft" /> Voltar
            </Button>
          )}
          <Button
            size="large"
            variant="primary"
            loading={isLoading}
            disabled={!stepProps.form?.isValid}
            onClick={stepProps.form?.submitForm}
            style={{ width: '250px' }}
          >
            {stepProps.button?.text || 'Continuar'}{' '}
            <Icons name={stepProps.button?.icon || 'IconArrowRight'} />
          </Button>
        </FooterButtons>
      </Footer>
      <CompanySignupSuccessModal
        open={openCompanySignupSuccessModal}
        onConfirm={onCompanySignupSuccessConfirm}
      />
      <SignupFinishModal
        open={openSignupFinishModal}
        onConfirm={onModalEndConfirm}
      />
      <FinishLaterModal
        open={openFinishLaterModal}
        isLastStep={step === 'add-admins' || step === 'commercial-conditions'}
        onCancel={() => setOpenFinishLaterModal(false)}
      />
    </MainContainer>
  );
};
