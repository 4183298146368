import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import { setInSS } from '@flash-tecnologia/hros-web-utility';
import { trackEvent, validateCNPJ } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSelfSignupStepNavigate } from '../../hooks/use-self-signup-step-navigate';
import { SelfSignupParams } from '../../hooks/use-self-signup';
import { useAuthenticationNavigate } from '../../hooks/use-authentication-navigate';

export const useCompanyInfoForm = () => {
  const { navigateToAuthentication } = useAuthenticationNavigate();
  const { navigateToStep } = useSelfSignupStepNavigate();
  const { authenticatedUser } = useCognitoAuthenticatedUser();

  const form = useFormik({
    initialValues,
    validationSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      trackEvent('self_signup_company_info_continue_clicked');

      const company: SelfSignupParams['company'] = {
        registrationNumber: values.registrationNumber.replace(/\D/g, ''),
        legalName: values.legalName,
        name: values.name,
        employeesQuantity: values.employeesQuantity,
        address: {
          zipCode: values.zipCode.replace(/\D/g, ''),
          street: values.street,
          number: values.number,
          complement: values.complement,
          district: values.district,
          city: values.city,
          state: values.state,
        },
      };

      setInSS({ key: 'selfSignupInfo', value: { company } });

      const {
        name,
        email,
        phone_number: phoneNumber,
      } = authenticatedUser?.attributes || {};

      if (!email || !phoneNumber || !name) {
        navigateToAuthentication('admin-info');
        return;
      }

      navigateToStep('admin-info');
    },
  });

  return { form };
};

const validationSchema = yup.object({
  registrationNumber: yup
    .string()
    .required('Por favor, digite o CNPJ da empresa')
    .test(
      'is-valid-cnpj',
      'Por favor, digite um CNPJ válido.',
      (value) => !value || validateCNPJ(value),
    ),
  legalName: yup.string().required('Por favor, digite o nome da empresa'),
  employeesQuantity: yup
    .string()
    .required('Por favor, digite a quantidade de funcionários da empresa')
    .test(
      'is-number',
      'Por favor, digite uma quantidade válida.',
      (value) => !value || !isNaN(Number(value)),
    ),
  zipCode: yup
    .string()
    .min(9, 'O CEP deve conter 8 dígitos')
    .required('Por favor, digite o CEP da empresa'),
  street: yup.string().required('Por favor, digite o Logradouro da empresa'),
  number: yup.string().required('Por favor, digite o Número da empresa'),
  district: yup.string().required('Por favor, digite a Cidade da empresa'),
  state: yup
    .string()
    .min(2, 'O Estado deve conter 2 caracteres')
    .required('Por favor, digite o Estado da empresa'),
  city: yup.string().required('Por favor, digite o Cidade da empresa'),
});

export type CompanyInfoFormValues = {
  registrationNumber: string;
  legalName: string;
  name: string;
  employeesQuantity: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  state: string;
  city: string;
};

const initialValues: CompanyInfoFormValues = {
  registrationNumber: '',
  legalName: '',
  name: '',
  employeesQuantity: '',
  zipCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  state: '',
  city: '',
};
