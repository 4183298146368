import { TextField } from '@/components/ds-overrides/TextField';
import styled from 'styled-components';

export const StepContainer = styled.form`
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.m};

  @media screen and (min-width: 1000px) {
    width: 500px;
  }
`;

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 781px) {
    padding-bottom: ${({ theme }) => theme.spacings.xs2};
  }
`;

export const StyledInput = styled(TextField)`
  margin-bottom: 4px;
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacings.xs};
  }
`;

export const NumberAndComplementContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: initial;
  }
`;

export const NumberInput = styled(StyledInput)`
  margin-top: ${({ theme }) => theme.spacings.xs};
  flex-basis: 48%;

  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const ComplementInput = styled(StyledInput)`
  flex-basis: 48%;
  order: 2;

  @media screen and (max-width: 768px) {
    order: 4;
    flex-basis: 100%;
  }
`;
