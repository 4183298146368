import { Divider } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Block = styled.div<{ filled?: boolean }>`
  min-width: 360px;
  border: 1px solid ${({ theme }) => theme.colors.secondary[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};

  background: ${({ theme, filled }) =>
    filled ? theme.colors.secondary[99] : 'transparent'};

  & + & {
    margin-top: ${({ theme }) => theme.spacings.xs1};
  }
`;

export const Section = styled.div<{ compact?: boolean }>`
  padding: ${({ compact, theme }) =>
    compact
      ? `${theme.spacings.xs3} ${theme.spacings.xs1}`
      : theme.spacings.xs1};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.secondary[90]};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const ConditionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & + & {
    margin-top: ${({ theme }) => theme.spacings.xs2};
  }

  & > * {
    flex-shrink: 0;
  }
`;

export const ConditionDivider = styled(Divider)`
  flex-shrink: 1;
  background: ${({ theme }) => theme.colors.secondary[90]};
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacings.xs5};
  margin-left: 40px;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`;
