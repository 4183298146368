import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2';
import { Modal } from '@flash-tecnologia/flamingo-ui-modal';

import { StyledPDFViewer } from './styles';

interface ModalProps {
  open: boolean;
  onClose: () => any;
}

export const TermsAndConditionsModal = ({ open, onClose }: ModalProps) => {
  const termsAndConditionsUrl =
    'https://drive.google.com/file/d/1CPRw0q8lXMqDIIIVrJheoOvouXzPt1vB/preview';

  return (
    <Modal.Root
      size="large"
      open={open}
      onOpenChange={(open) => !open && onClose!()}
    >
      <Modal.Content.Root>
        <Modal.Content.Title>
          Termos e Condições Gerais de Uso
        </Modal.Content.Title>
        <Modal.Content.Description>
          Para usar nossos serviços, você deve ler atentamente e aceitar aos
          Termos e Condições Gerais de Uso da Flash.
        </Modal.Content.Description>

        <StyledPDFViewer
          width="100%"
          height="400px"
          src={termsAndConditionsUrl}
          options={{ showToolbar: true, fit: 'height', page: 1 }}
        />

        <Modal.Content.Footer>
          <LinkButton
            variant="default"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          >
            Fechar
          </LinkButton>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  );
};
