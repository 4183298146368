import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { SelfSignupSteps } from '../types';

export const useAuthenticationNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToAuthentication = (stepToReturn: SelfSignupSteps) => {
    navigate({
      pathname: '/authentication/first-access',
      search: createSearchParams({
        redirectTo: `/signup/company/${stepToReturn}?${searchParams.toString()}`,
        variant: 'simplified',
      }).toString(),
    });
  };

  return { navigateToAuthentication };
};
