import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export enum SignupSteps {
  CREATE_COMPANY = 'create-company',
  ADMIN_INFO = 'admin-info',
  ADD_ADMINS = 'add-admins',
  COMMERCIAL_CONDITIONS = 'commercial-conditions',
}

export const useSignupStepNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { dealId } = useParams();

  const navigateToStep = (
    step: SignupSteps,
    params: Record<string, string> = {},
  ) => {
    const basePath = dealId ? `/signup/hubspot/${dealId}` : '/signup/self';

    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }

    navigate({
      pathname: `${basePath}/${step}`,
      search: searchParams.toString(),
    });
  };

  return { navigateToStep };
};
