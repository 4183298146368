import { Radio } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacings.xs1};
  gap: ${({ theme }) => theme.spacings.xs2};
  margin-top: ${({ theme }) => theme.spacings.xs1};
  border: solid 1px ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const OptionsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.m};
`;

export const RadioContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs3};
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
`;

export const StyledRadio = styled(Radio)`
  padding: 0px;
`;
