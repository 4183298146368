import { ErrorBoundary, trackEvent, validateCPF } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { useState } from 'react';
import { useCreateEmployees } from '../../hooks/use-create-employees';
import { useSignup } from '../../hooks/use-signup';

export type Admin = {
  name: string;
  documentNumber: string;
  hasNoEmail: boolean;
  email: string;
  phoneNumber: string;
  permission: 'SUPER_ADMIN' | 'ADMIN';
};

export type AdminFormValue = {
  admins: Admin[];
};

type AddAdminsFormProps = {
  onNext: () => void;
};

export const useAddAdminsForm = ({ onNext }: AddAdminsFormProps) => {
  const { createEmployees } = useCreateEmployees();
  const [isLoading, setIsLoading] = useState(false);

  const form = useFormik({
    validationSchema: adminsSchema,
    initialValues: { admins: [] as Admin[] },
    isInitialValid: true,
    onSubmit: async (values) => {
      trackEvent('signup_add_admins_continue_clicked', {
        admins_count: values.admins.length.toString(),
      });

      setIsLoading(true);

      if (values.admins.length === 0) {
        onNext();
        setIsLoading(false);
        return;
      }

      const signupResponse = getFromSS('temporaryCompanyInfos');

      try {
        await createEmployees({
          userEmployeeId: signupResponse.employeeId,
          companyId: signupResponse.companyId,
          roleId: signupResponse.roleId,
          employees: values.admins.map((admin) => ({
            name: admin.name,
            documentNumber: admin.documentNumber,
            email: admin.email,
            phoneNumber: admin.phoneNumber,
            permission: admin.permission,
          })),
        });

        onNext();
      } catch (error) {
        ErrorBoundary.captureException(error as Error);
      }

      setIsLoading(false);
    },
  });

  return { form, isLoading };
};

const adminsSchema = yup.object().shape({
  admins: yup.array().of(
    yup.object().shape({
      name: yup.string().required('Favor digitar um nome'),
      documentNumber: yup
        .string()
        .required('Favor digitar um CPF')
        .test(
          'validateCPF',
          'CPF inválido',
          (value) => !!value && validateCPF(value),
        ),
      hasNoEmail: yup.boolean(),
      email: yup.string().when('hasNoEmail', {
        is: true,
        then: yup.string(),
        otherwise: yup.string().required('Digite um email válido'),
      }),
      phoneNumber: yup
        .string()
        .when('hasNoEmail', {
          is: true,
          then: yup.string().required('Favor digitar um celular'),
          otherwise: yup.string(),
        })
        .test(
          'validatePhoneNumber',
          'Telefone inválido',
          (value) => !value || value?.replace(/\D/g, '').length === 13,
        ),
      permission: yup
        .string()
        .oneOf(['SUPER_ADMIN', 'ADMIN'])
        .required('Favor selecionar um nível de permissão'),
    }),
  ),
});
