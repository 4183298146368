import {
  Icons,
  LinkButton,
  ShapeIcon,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Block,
  CategoryContainer,
  CategoryItem,
  ConditionDivider,
  ConditionItem,
  Header,
  Section,
} from './commercial-conditions.styles';
import { faqUrl, trackEvent } from '@/utils';

const benefitsCardCategories = [
  'Vale alimentação e refeição',
  'Saúde',
  'Vale transporte',
  'Mobilidade',
  'Educação',
  'Home office',
  'Cultura',
  'Premiação',
  'Multibenefícios',
];

export const CommercialConditions = () => {
  const onFaqClick = () => {
    trackEvent('self_signup_faq_click');
    window.open(faqUrl, '_blank');
  };

  return (
    <div>
      <Typography.Body3
        color="brand.50"
        weight={600}
        style={{ marginBottom: 12 }}
      >
        Produtos incluídos
      </Typography.Body3>
      <Block>
        <Section>
          <Header>
            <ShapeIcon
              variant="default"
              name="IconShoppingCart"
              color="var(--color-secondary-50)"
              size={24}
              style={{ padding: 4 }}
            />
            <Typography.Body4 color="neutral.30" weight={700}>
              Cartão Flash Benefícios
            </Typography.Body4>
            <Tag variant="green" size="extra-small">
              Gratuito!
            </Tag>
          </Header>
          <CategoryContainer>
            {benefitsCardCategories.map((category, i) => (
              <CategoryItem key={i}>
                <Icons
                  name="IconCheck"
                  color="var(--color-secondary-50)"
                  size={16}
                />
                <Typography.Body4 color="neutral.30">
                  {category}
                </Typography.Body4>
              </CategoryItem>
            ))}
          </CategoryContainer>
        </Section>
        <Section>
          <ConditionItem>
            <Typography.Body4 color="neutral.30" weight={600}>
              Taxas e tarifas para contratação
            </Typography.Body4>
            <ConditionDivider orientation="horizontal" />
            <Typography.Body4 color="neutral.30" weight={700}>
              R$ 0,00
            </Typography.Body4>
          </ConditionItem>
        </Section>
      </Block>
      <Block>
        <Section>
          <Header>
            <ShapeIcon
              variant="default"
              name="IconBriefcase"
              color="var(--color-secondary-50)"
              size={24}
              style={{ padding: 4 }}
            />
            <Typography.Body4 color="neutral.30" weight={700}>
              Cartão Corporativo
            </Typography.Body4>
            <Tag variant="green" size="extra-small">
              Gratuito!
            </Tag>
          </Header>
        </Section>
        <Section>
          <ConditionItem>
            <Typography.Body4 color="neutral.30" weight={600}>
              Taxas e tarifas para contratação
            </Typography.Body4>
            <ConditionDivider orientation="horizontal" />
            <Typography.Body4 color="neutral.30" weight={700}>
              R$ 0,00
            </Typography.Body4>
          </ConditionItem>
        </Section>
      </Block>
      <Block>
        <Section>
          <ConditionItem>
            <Typography.Body4 color="neutral.30" weight={600}>
              Quantidade mínima de funcionários
            </Typography.Body4>
            <ConditionDivider orientation="horizontal" />
            <Tag variant="green" size="extra-small">
              Sem mínimo
            </Tag>
          </ConditionItem>
          <ConditionItem>
            <Typography.Body4 color="neutral.30" weight={600}>
              Multa em caso de rescisão
            </Typography.Body4>
            <ConditionDivider orientation="horizontal" />
            <Tag variant="green" size="extra-small">
              Sem multa
            </Tag>
          </ConditionItem>
        </Section>
      </Block>
      <Block filled>
        <Section compact>
          <Header>
            <ShapeIcon
              variant="default"
              name="IconHelp"
              color="var(--color-secondary-50)"
              size={24}
              style={{ padding: 4 }}
            />
            <Typography.Body4 color="neutral.30" weight={700}>
              Dúvidas sobre a contratação?
            </Typography.Body4>
            <LinkButton
              variant="default"
              onClick={onFaqClick}
              style={{ lineHeight: '24px' }}
            >
              Acesse nosso FAQ
            </LinkButton>
          </Header>
        </Section>
      </Block>
    </div>
  );
};
