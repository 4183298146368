import { Button, Icons, useMediaQuery } from '@flash-tecnologia/hros-web-ui-v2';
import * as SC from './self-signup.styles';
import LogoFlash from '../../../assets/logo_flash.svg';
import { useEffect } from 'react';
import { trackEvent, trackPage } from '@/utils';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  AboutProductSection,
  BannerSection,
  FaqSection,
  StepsDescriptionSection,
  VideoShowcaseSection,
} from './sections';
import { useGetLpVariant } from './hooks/use-get-lp-variant';
import { useScrollToHashElement } from './hooks/use-scroll-to-hash-element';
import { useFlag } from '@flash-tecnologia/feature-flags';

export const SelfSignupLandingPage = () => {
  const isNewExperience = useFlag({
    flagName: 'FLASH_OS_NEW_SELF_SIGNUP_EXPERIENCE',
  });
  const variant = useGetLpVariant();
  const isMobile = useMediaQuery('(max-width: 500px)');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useScrollToHashElement();

  useEffect(() => {
    trackPage('signup_self_signup_landing_page', { variant: variant.name });
  }, []);

  const onSupportClick = () => {
    trackEvent('signup_self_signup_landing_page_ask_support_clicked', {
      variant: variant.name,
    });
  };

  const onCtaClick = (section: string) => {
    trackEvent('signup_self_signup_landing_page_cta_clicked', {
      variant: variant.name,
      section,
    });

    if (isNewExperience) {
      navigate({
        pathname: '/signup/company/company-info',
        search: searchParams.toString(),
      });
      return;
    }

    const params = createSearchParams({
      variant: 'company',
      redirectTo: `/signup/self/create-company?source=lp-self-signup&${searchParams.toString()}`,
    });

    navigate({
      pathname: '/authentication/first-access',
      search: params.toString(),
    });
  };

  return (
    <SC.Container>
      <SC.Header>
        <LogoFlash />
      </SC.Header>
      <SC.ContentContainer>
        <BannerSection headline={variant.payload.banner.headline} />
        <AboutProductSection onCtaClick={() => onCtaClick('about_product')} />
        <StepsDescriptionSection
          onCtaClick={() => onCtaClick('signup_steps')}
        />
        <VideoShowcaseSection onCtaClick={() => onCtaClick('video')} />
        <FaqSection />
      </SC.ContentContainer>
      <SC.Footer>
        <Button
          size="medium"
          variant="primary"
          onClick={() => onCtaClick('footer')}
        >
          {isMobile ? 'Continuar' : 'Continuar cadastro'}{' '}
          <Icons name="IconArrowRight" />
        </Button>
      </SC.Footer>
    </SC.Container>
  );
};
