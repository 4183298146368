import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LinkButton,
  Modal,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { getFromLS } from '@flash-hros/utility';

import { ButtonsContainer, StyledButton, ModalContent } from './styles';
import { hrosLoginUrl } from '@utils';

interface ModalProps {
  open: boolean;
  onConfirm: () => void;
}

export const SignupFinishModal = ({ open, onConfirm }: ModalProps) => {
  const navigate = useNavigate();

  const userAuth = useMemo(() => getFromLS('userAuth'), []);
  const userInfos = useMemo(() => {
    if (userAuth?.attributes?.name) {
      const userFirstName = userAuth?.attributes?.name.split(' ')[0];
      return { firstName: userFirstName || '' };
    }
  }, [userAuth]);

  return (
    <Modal.Root open={open} onClose={onConfirm} showClose={false}>
      <ModalContent>
        <ShapeIcon
          variant="default"
          name="IconCheck"
          color="var(--color-secondary-50)"
          strokeWidth="1.5px"
          size={64}
        />
        <Typography variant="body3" color="brand.50" weight={700}>
          Parabéns
          {userInfos?.firstName ? ', ' + userInfos.firstName + '!' : '!'}
        </Typography>

        <Typography
          variant="headline6"
          color="neutral.20"
          style={{ textAlign: 'center' }}
        >
          Você acaba de fazer uma escolha que facilita todas as outras
        </Typography>
        <Typography
          variant="body4"
          color="neutral.50"
          style={{ textAlign: 'center' }}
        >
          O cadastro da sua empresa foi criado com sucesso! Aproveite para
          configurar a plataforma e começar impulsionar sua gestão de
          colaboradores
        </Typography>
        <ButtonsContainer>
          <LinkButton variant="default" onClick={() => navigate(hrosLoginUrl)}>
            Agora não
          </LinkButton>
          <StyledButton
            size="large"
            variant="primary"
            children="Configurar plataforma"
            onClick={onConfirm}
          />
        </ButtonsContainer>
      </ModalContent>
    </Modal.Root>
  );
};
