import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Container,
  OptionsContainer,
  StyledRadio,
  RadioContainer,
  TextContainer,
} from './styles';

type PermissionsCardProps = {
  permission: string;
  setPermission: (permission: string) => void;
  disableAdminRole?: boolean;
};

export const PermissionsCard = ({
  permission,
  setPermission,
  disableAdminRole,
}: PermissionsCardProps) => {
  return (
    <Container>
      <TextContainer>
        <Typography.Headline9 color="neutral.30">
          Selecione o nível de permissão
        </Typography.Headline9>
        <Typography.Body4 color="neutral.50">
          Os super administradores possuem nível máximo de permissão na
          plataforma, tendo acesso a informações e configurações de{' '}
          <strong>todas as empresas do grupo</strong>. Já os administradores
          comuns têm acesso apenas à empresa vinculada à conta no momento da
          criação.
        </Typography.Body4>
      </TextContainer>
      <OptionsContainer>
        <RadioContainer onClick={() => setPermission('SUPER_ADMIN')}>
          <StyledRadio checked={permission === 'SUPER_ADMIN'} />
          <Typography.Body3 color="neutral.30">
            Super administrador
          </Typography.Body3>
        </RadioContainer>
        <RadioContainer
          disabled={disableAdminRole}
          onClick={() => {
            if (!disableAdminRole) setPermission('ADMIN');
          }}
        >
          <StyledRadio
            checked={permission === 'ADMIN'}
            disabled={disableAdminRole}
          />
          <Typography.Body3
            color={disableAdminRole ? 'neutral.50' : 'neutral.30'}
          >
            Administrador comum
          </Typography.Body3>
        </RadioContainer>
      </OptionsContainer>
    </Container>
  );
};
