import {
  Button,
  Checkbox,
  CircularProgress,
  Icons,
  LinkButton,
  PillButton,
  TextField,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { FieldArray, FormikErrors, FormikProps, FormikProvider } from 'formik';
import { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {
  Form,
  FormHeader,
  FormsContainer,
  CheckBoxContainer,
  CheckBoxLabel,
  TextFieldContainer,
} from './add-admins.styles';
import { trackEvent, trackPage } from '@/utils';
import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import {
  MainContainer,
  MessageContainer,
} from '../CompanyInfo/company-info.styles';
import { Admin, AdminFormValue } from './add-admins.hooks';
import { PermissionsCard } from './components/PermissionsCard';
import { useRetry } from '@/utils/hooks/use-retry';
import { useGetAdminRole } from '../../hooks/use-get-admin-role';
import { LoadingWrapper } from '../ComercialConditions/commercial-conditions.styles';

type AddAdminsProps = {
  form: FormikProps<AdminFormValue>;
  dealId;
  loading;
};

export const AddAdmins = ({ form, dealId }: AddAdminsProps) => {
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const { authenticatedUser, loading } = useCognitoAuthenticatedUser();
  const [adminRoleId, setAdminRoleId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { getAdminRole } = useGetAdminRole();
  const { startPolling, loading: getAdminRoleLoading } = useRetry({
    maxAttempts: 5,
    interval: 2000,
    query: getAdminRole,
    stopCondition: (data) => {
      return data?.roles?.length > 0;
    },
    onCompleted: (data) => {
      if (data?.roles.length > 0) {
        setAdminRoleId(data.roles[0].id);
      }
      setIsLoading(false);
    },
  });

  const addAdmin = () => {
    form.setFieldValue('admins', [...form.values.admins, {}]);
  };

  const skipStep = () => {
    trackEvent('signup_add_admins_skip_clicked');
    form.submitForm();
  };

  useEffect(() => {
    trackPage('signup_add_admins_page');

    const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
    if (!temporaryCompanyInfos) {
      const redirectPath = !!dealId
        ? `/signup/hubspot/${dealId}/create-company?${searchParams.toString()}`
        : '/signup/self/create-company';
      navigate(redirectPath);
      return;
    }

    startPolling({
      companyId: temporaryCompanyInfos.companyId,
      employeeId: temporaryCompanyInfos.employeeId,
    });
  }, []);

  useEffect(() => {
    if (!loading && !authenticatedUser) {
      const redirectPath = !!dealId
        ? `/signup/hubspot/${dealId}/add-admins`
        : '/signup/self/add-admins';

      navigate({
        pathname: '/authentication/first-access',
        search: createSearchParams({
          redirectTo: `${redirectPath}?${searchParams.toString()}`,
        }).toString(),
      });
    }
  }, [authenticatedUser, loading]);

  const errors = (form.errors.admins || []) as FormikErrors<Admin[]>;
  const hasError = (field: string, index: number) =>
    form.touched.admins?.[index]?.[field] && Boolean(errors[index]?.[field]);

  if (isLoading || getAdminRoleLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress variant="indeterminate" size={80} />
      </LoadingWrapper>
    );
  }

  return (
    <MainContainer>
      <MessageContainer>
        <Typography.Body3
          color="brand.50"
          weight={700}
          children="Adicionar outros administradores"
        />
        <Typography.Headline5 children="Você já é um administrador da conta! Gostaria de cadastrar outros agora?" />
        <Typography.Body3
          color="neutral.50"
          children="Você também poderá cadastrar outros administradores após acessar a nossa plataforma."
        />
      </MessageContainer>
      {form.values.admins.length > 0 && (
        <div>
          <Typography.Headline9 color="neutral.30" style={{ marginBottom: 16 }}>
            Preencha os dados para envio do convite de acesso
          </Typography.Headline9>

          <FormikProvider value={form}>
            <FieldArray
              name="admins"
              render={(arrayHelpers) => (
                <FormsContainer>
                  {form.values.admins.map((admin, index) => (
                    <Form key={`admin-form-${index}`}>
                      <FormHeader>
                        <Typography.Headline8 color="neutral.30">
                          Convidar outro administrador
                        </Typography.Headline8>
                        <PillButton
                          variant="default"
                          icon="IconTrash"
                          size="extra-small"
                          onClick={() => arrayHelpers.remove(index)}
                        />
                      </FormHeader>
                      <div>
                        <Typography.Body3 color="feedback.error.50">
                          *{' '}
                          <Typography.Body3 as="span" color="neutral.50">
                            campo obrigatório
                          </Typography.Body3>
                        </Typography.Body3>
                      </div>
                      <TextField
                        required
                        fullWidth
                        label="Nome Completo"
                        id={`admins.${index}.name`}
                        name={`admins.${index}.name`}
                        value={admin.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        error={hasError('name', index)}
                        helperText={
                          hasError('name', index) && errors[index]?.name
                        }
                        style={{ marginTop: 16 }}
                      />
                      <TextField
                        required
                        fullWidth
                        label="CPF"
                        imaskProps={{
                          mask: '000.000.000-00',
                        }}
                        autoComplete="off"
                        id={`admins.${index}.documentNumber`}
                        name={`admins.${index}.documentNumber`}
                        value={admin.documentNumber}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        error={hasError('documentNumber', index)}
                        helperText={
                          hasError('documentNumber', index) &&
                          errors[index]?.documentNumber
                        }
                        style={{ marginTop: 24 }}
                      />
                      <TextFieldContainer>
                        <TextField
                          required={!admin.hasNoEmail}
                          disabled={admin.hasNoEmail}
                          fullWidth
                          label="E-mail para envio do convite"
                          id={`admins.${index}.email`}
                          name={`admins.${index}.email`}
                          value={admin.email}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          error={hasError('email', index)}
                          helperText={
                            hasError('email', index)
                              ? errors[index]?.email
                              : 'Enviaremos o convite de primeiro acesso à plataforma e app da Flash para esse e-mail.'
                          }
                        />
                        <CheckBoxContainer>
                          <Checkbox
                            value={admin.hasNoEmail}
                            size="small"
                            onChange={(e) => {
                              form.setFieldValue(`admins.${index}.email`, '');
                              form.setFieldValue(
                                `admins.${index}.hasNoEmail`,
                                e.target.checked,
                                true,
                              );
                            }}
                          />
                          <CheckBoxLabel.Body3 weight={600}>
                            Não sei o e-mail
                          </CheckBoxLabel.Body3>
                        </CheckBoxContainer>
                      </TextFieldContainer>
                      <TextField
                        required={admin.hasNoEmail}
                        fullWidth
                        label="Celular"
                        imaskProps={{ mask: '+55 00 90000-0000' }}
                        autoComplete="off"
                        id={`admins.${index}.phoneNumber`}
                        name={`admins.${index}.phoneNumber`}
                        value={admin.phoneNumber}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        error={hasError('phoneNumber', index)}
                        helperText={
                          hasError('phoneNumber', index)
                            ? errors[index]?.phoneNumber
                            : 'Caso não possua um celular empresarial, preencha com um número pessoal.'
                        }
                        style={{ marginTop: 24 }}
                      />
                      <PermissionsCard
                        permission={admin.permission}
                        setPermission={(permission) =>
                          form.setFieldValue(
                            `admins.${index}.permission`,
                            permission,
                          )
                        }
                        disableAdminRole={!adminRoleId}
                      />
                    </Form>
                  ))}
                </FormsContainer>
              )}
            />
          </FormikProvider>
        </div>
      )}
      <Button variant="secondary" onClick={addAdmin} style={{ width: '100%' }}>
        Convidar outro administrador <Icons name="IconPlus" />
      </Button>
      {form.values.admins.length === 0 && (
        <LinkButton
          variant="neutral"
          onClick={skipStep}
          style={{ alignSelf: 'center' }}
        >
          Pular etapa
        </LinkButton>
      )}
    </MainContainer>
  );
};
