import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import { setInSS } from '@flash-tecnologia/hros-web-utility';
import { formatCompanySignupPhone, trackEvent, validateCNPJ } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  SignupSteps,
  useSignupStepNavigate,
} from '../../hooks/use-step-navigate';
import { useSignup } from '../../hooks/use-signup';
import { useState } from 'react';

export const useCompanyInfoForm = ({
  dealId,
  leadId,
  onSignupSuccess,
}: CompanyInfoFormProps) => {
  const { navigateToStep } = useSignupStepNavigate();
  const { authenticatedUser } = useCognitoAuthenticatedUser();
  const { signup, isLoading: signupLoading } = useSignup();
  const isSelfSignup = location.pathname.includes('self');

  const [isLoading, setIsLoading] = useState(false);

  const form = useFormik({
    initialValues,
    validationSchema: getValidationSchema(isSelfSignup),
    initialErrors: {},
    validate: (values) => {
      let errors: any = {};
      if (values.registrationNumber && !validateCNPJ(values.registrationNumber))
        errors.registrationNumber = 'Por favor, digite um CNPJ válido.';
      if (values.employeesQuantity && isNaN(Number(values.employeesQuantity)))
        errors.employeesQuantity = 'Por favor, digite uma quantidade valida.';
      return errors;
    },
    onSubmit: async (values) => {
      trackEvent('signup_create_company_continue_clicked');

      setIsLoading(true);
      const company = {
        registrationNumber: values.registrationNumber.replace(/\D/g, ''),
        legalName: values.legalName,
        name: values.name,
        employeesQuantity: values.employeesQuantity,
        address: {
          zipCode: values.zipCode.replace(/\D/g, ''),
          street: values.street,
          number: values.number,
          complement: values.complement,
          district: values.district,
          city: values.city,
          state: values.state,
        },
      };

      setInSS({ key: 'temporaryCompanyInfos', value: company });

      const admin = {
        email: authenticatedUser.attributes.email,
        name: authenticatedUser.attributes?.name || '',
        documentNumber: authenticatedUser.attributes.preferred_username.replace(
          /\D/g,
          '',
        ),
        phone: !!authenticatedUser.attributes.phone_number
          ? formatCompanySignupPhone(
              authenticatedUser.attributes.phone_number.slice(3),
            )
          : '',
      };

      if (!admin.email || !admin.phone || !admin.name) {
        navigateToStep(SignupSteps.ADMIN_INFO);
        setIsLoading(false);
        return;
      }

      const signupResult = await signup({
        company,
        admin,
        dealId,
        leadId,
      });

      if (signupResult) {
        onSignupSuccess(signupResult.contractId);
      }
    },
  });

  return { form, isLoading: isLoading || signupLoading };
};

type CompanyInfoFormProps = {
  leadId?: string;
  dealId?: string;
  onSignupSuccess: (contractId?: string) => void;
};

const getValidationSchema = (isSelfSignup: boolean) =>
  yup.object({
    registrationNumber: yup
      .string()
      .required('Por favor, digite o CNPJ da empresa'),
    legalName: yup.string().required('Por favor, digite o nome da empresa'),
    employeesQuantity: yup.string().when([], {
      is: () => isSelfSignup,
      then: yup
        .string()
        .required('Por favor, digite a quantidade de funcionários da empresa'),
      otherwise: yup.string(),
    }),
    zipCode: yup
      .string()
      .min(9, 'O CEP deve conter 8 dígitos')
      .required('Por favor, digite o CEP da empresa'),
    street: yup.string().required('Por favor, digite o Logradouro da empresa'),
    number: yup.string().required('Por favor, digite o Número da empresa'),
    district: yup.string().required('Por favor, digite a Cidade da empresa'),
    state: yup
      .string()
      .min(2, 'O Estado deve conter 2 caracteres')
      .required('Por favor, digite o Estado da empresa'),
    city: yup.string().required('Por favor, digite o Cidade da empresa'),
    termChecked: yup.boolean().isTrue(),
  });

export type CompanyInfoFormValues = {
  registrationNumber: string;
  legalName: string;
  name: string;
  employeesQuantity: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  district: string;
  state: string;
  city: string;
  termChecked: boolean;
};

const initialValues: CompanyInfoFormValues = {
  registrationNumber: '',
  legalName: '',
  name: '',
  employeesQuantity: '',
  zipCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  state: '',
  city: '',
  termChecked: false,
};
