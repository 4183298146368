import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  height: 500px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Headline = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs2};
`;

export const Hyperlink = styled(Typography).attrs({
  variant: 'body4',
  color: 'brand.50',
  weight: 600,
})`
  display: inline;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;
