import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { formatCompanySignupPhone, trackEvent, validateEmail } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SignupParams, useSignup } from '../../hooks/use-signup';

export const useAdminInfoForm = ({
  onSignupSuccess,
  dealId,
  leadId,
}: AdminInfoFormProps) => {
  const { signup, isLoading: signupLoading } = useSignup();

  const form = useFormik({
    initialValues,
    validationSchema,
    isInitialValid: false,
    validate: (values) => {
      let errors: any = {};
      if (values.email && !validateEmail(values.email))
        errors.email = 'Por favor, digite um e-mail válido.';
      return errors;
    },
    onSubmit: async (values) => {
      trackEvent('signup_admin_info_continue_clicked');

      const temporaryCompanyInfos = getFromSS('temporaryCompanyInfos');
      const company: SignupParams['company'] = {
        registrationNumber: temporaryCompanyInfos?.registrationNumber,
        legalName: temporaryCompanyInfos?.legalName,
        name: temporaryCompanyInfos?.name,
        employeesQuantity: temporaryCompanyInfos?.employeesQuantity,
        address: {
          zipCode: temporaryCompanyInfos?.address?.zipCode,
          street: temporaryCompanyInfos?.address?.street,
          number: temporaryCompanyInfos?.address?.number,
          complement: temporaryCompanyInfos?.address?.complement || '',
          district: temporaryCompanyInfos?.address?.district,
          city: temporaryCompanyInfos?.address?.city,
          state: temporaryCompanyInfos?.address?.state,
        },
      };

      const admin: SignupParams['admin'] = {
        email: values.email,
        phone: formatCompanySignupPhone(values.phone),
        name: values.name,
        documentNumber: values.documentNumber.replace(/\D/g, ''),
      };

      const signupResult = await signup({
        company,
        admin,
        dealId,
        leadId,
      });

      if (signupResult) {
        onSignupSuccess(signupResult.contractId);
      }
    },
  });

  return { form, isLoading: form.isSubmitting || signupLoading };
};

const validationSchema = yup.object({
  name: yup.string().required('Por favor, digite o nome do administrador'),
  phone: yup
    .string()
    .min(16, 'O número deve conter 11 dígitos')
    .required('Por favor, digite o número de celular do administrador'),
  email: yup.string().required('Por favor, digite o e-mail do administrador'),
});

export type AdminInfoFormValues = {
  email: string;
  name: string;
  documentNumber: string;
  phone: string;
};

const initialValues: AdminInfoFormValues = {
  email: '',
  name: '',
  documentNumber: '',
  phone: '',
};

type AdminInfoFormProps = {
  signupType?: string;
  dealId?: string;
  leadId?: string;
  onSignupSuccess: (contractId?: string) => void;
};
