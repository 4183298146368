import { TextField as DsTextField } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const TextField = styled(DsTextField)`
  .MuiInputLabel-root {
    top: 0;
    line-height: 20px;
    transform: translate(16px, 16px) scale(1);
    font-size: 0.875rem;
  }

  .MuiInputLabel-shrink {
    line-height: inherit;
    transform: translate(17px, 7px) scale(0.75);
    font-size: 0.75rem;
    line-height: 18px;
  }

  .MuiFilledInput-root {
    border-color: ${({ theme }) => theme.colors.neutral[90]};
  }

  .MuiFilledInput-input {
    color: var(--color-neutral-20);
    border-radius: 12px;
    font-size: 0.875rem;
    line-height: 20px;
    padding: 20px 16px 5px;
  }

  .Mui-disabled,
  .Mui-disabled input {
    color: var(--color-neutral-70);
    background: var(--color-neutral-95);
    border-color: var(--color-neutral-80);
  }
`;
