import { useEffect } from 'react';
import { FormikProps } from 'formik';
import { CircularProgress, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StyledInput,
  FieldsContainer,
  StepContainer,
} from '../CompanyInfo/company-info.styles';
import { AdminInfoFormValues } from './admin-info.hooks';
import { trackPage } from '@/utils';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { useCognitoAuthenticatedUser } from '@/utils/hooks/use-authenticated-user';
import {
  useAuthenticationNavigate,
  useSelfSignupStepNavigate,
} from '../../hooks';
import { LoadingWrapper } from '../Contract/contract.styles';
import { InputHelperText } from '../CompanyInfo/components/InputHelperText';

type AdminInfoProps = {
  form: FormikProps<AdminInfoFormValues>;
};

export const AdminInfo = ({ form }: AdminInfoProps) => {
  const { navigateToStep } = useSelfSignupStepNavigate();
  const { navigateToAuthentication } = useAuthenticationNavigate();
  const { authenticatedUser, loading: userLoading } =
    useCognitoAuthenticatedUser();

  useEffect(() => {
    trackPage('self_signup_admin_info_page');

    const selfSignupInfo = getFromSS('selfSignupInfo');
    if (!selfSignupInfo) {
      navigateToStep('company-info');
    }
  }, []);

  useEffect(() => {
    if (userLoading) return;
    if (!authenticatedUser) {
      navigateToAuthentication('admin-info');
      return;
    }

    form.setValues({
      email: authenticatedUser.attributes.email,
      name: authenticatedUser.attributes?.name || '',
      documentNumber: authenticatedUser.attributes.preferred_username.replace(
        /\D/g,
        '',
      ),
      phone: authenticatedUser.attributes.phone_number?.slice(3),
    });
  }, [authenticatedUser, userLoading]);

  // Formik workaround to validate the form
  // when the fields are populated programmatically
  useEffect(() => {
    form.validateForm();
  }, [form.values]);

  if (userLoading) {
    return (
      <LoadingWrapper>
        <CircularProgress variant="indeterminate" size={80} />
      </LoadingWrapper>
    );
  }

  return (
    <StepContainer>
      <Typography.Headline7 color="neutral.30">
        Confirme seus dados
      </Typography.Headline7>
      <FieldsContainer>
        <StyledInput
          required
          label="Nome completo"
          id="name"
          name="name"
          fullWidth
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.name && Boolean(form.errors.name)}
        />
        {form.touched.name && Boolean(form.errors.name) && (
          <InputHelperText>{form.errors.name}</InputHelperText>
        )}

        <StyledInput
          required
          label="E-mail pessoal"
          id="email"
          name="email"
          fullWidth
          value={form.values.email}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.email && Boolean(form.errors.email)}
        />
        {form.touched.email && Boolean(form.errors.email) && (
          <InputHelperText>{form.errors.email}</InputHelperText>
        )}

        <StyledInput
          required
          label="Celular"
          id="phone"
          name="phone"
          fullWidth
          value={form.values.phone}
          imaskProps={{ mask: '(00) 0 0000-0000' }}
          onChange={(e) => {
            // For some unknown issue, when using imaskProps
            // the formik is receiving the values as the initialState
            // which is messing with the validation and the other fields
            // Workaround:
            form.setFieldValue('phone', e.target.value, true);
          }}
          onBlur={form.handleBlur}
          error={form.touched.phone && Boolean(form.errors.phone)}
        />
        {form.touched.phone && Boolean(form.errors.phone) && (
          <InputHelperText>{form.errors.phone}</InputHelperText>
        )}
      </FieldsContainer>
    </StepContainer>
  );
};
