import { getCep as getUtilityCep } from '@flash-tecnologia/hros-web-utility';
import axios from 'axios';

export const useGetCep = () => {
  const getCep = async (zipCode: string): Promise<Cep | null> => {
    try {
      const cepResponse = await axios<GetCepResponse>({
        method: 'GET',
        url: `${process.env.FLASHOS_GATEWAY_URL}/v1/cep/${zipCode}`,
      });

      return {
        street: cepResponse.data.street,
        complement: cepResponse.data.complement,
        district: cepResponse.data.neighborhood,
        city: cepResponse.data.city,
        state: cepResponse.data.state,
      };
    } catch {
      const data = await getUtilityCep(zipCode);

      return {
        street: data.logradouro,
        complement: data.complemento,
        district: data.bairro,
        city: data.localidade,
        state: data.uf,
      };
    }
  };

  return { getCep };
};

type Cep = {
  street: string;
  complement?: string;
  district: string;
  city: string;
  state: string;
};

type GetCepResponse = {
  zip_code: string;
  street: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
};
