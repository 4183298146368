import { dispatchToast } from '../../../utils';
import { trpc } from '@api/client';

export const useSelfSignup = () => {
  const { mutateAsync, isLoading } = trpc.companySelfSignup.useMutation({
    trpc: { context: { useCognitoToken: true } },
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ops! Não foi possível cadastrar sua empresa no momento. Tente novamente mais tarde ou fale com nosso suporte.',
      });
    },
  });

  const companySelfSignUp = async ({
    company,
    admin,
    selfSignupToken,
  }: SelfSignupParams) => {
    if (isLoading) return;

    const response = await mutateAsync({
      company,
      employee: admin,
      selfSignupToken: selfSignupToken || '',
    });
    return {
      companyId: response.company.value.companyId,
      employeeId: response.company.value.employeeId,
      contractId: response.contractIds[0],
    };
  };

  return { companySelfSignUp, isLoading };
};

export type SelfSignupParams = {
  company: {
    registrationNumber: string;
    legalName: string;
    name: string;
    employeesQuantity: string;
    address: {
      zipCode: string;
      street: string;
      number: string;
      complement: string;
      district: string;
      city: string;
      state: string;
    };
  };
  admin: {
    email: string;
    name: string;
    documentNumber: string;
    phone: string;
  };
  selfSignupToken?: string;
};
