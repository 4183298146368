import { useEffect, useRef, useState } from 'react';
import {
  Navigate,
  createSearchParams,
  useNavigate,
  useParams,
} from 'react-router-dom';

import {
  Icons,
  IconsProps,
  PillButton,
  useMediaQuery,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  AbsoluteOverlay,
  Content,
  Footer,
  Header,
  HiddenRightBlock,
  LeftBlock,
  MainContainer,
  RightBlock,
  ScrollArea,
} from './self-signup.styles';

import LogoFlash from '../../assets/flash.svg';
import { useCompanyInfoForm } from './steps/CompanyInfo/company-info.hooks';
import { useAdminInfoForm } from './steps/AdminInfo/admin-info.hooks';
import { useContractForm } from './steps/Contract/contract.hooks';
import { AdminInfo, Contract, CompanyInfo } from './steps';
import { useSelfSignupStepNavigate } from './hooks/use-self-signup-step-navigate';
import { CommercialConditions } from './components/CommercialConditions';
import { ProgressBar } from './components/ProgressBar';
import { Button } from '@/components/ds-overrides/Button';

export const SelfSignup = () => {
  const refToTop = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { navigateToStep } = useSelfSignupStepNavigate();
  const { step } = useParams();
  const showRightBlock = useMediaQuery('(min-width: 876px)');

  const [showHiddenRightBlock, setShowHiddenRightBlock] = useState(false);

  const onCompanyInfoBack = () => navigate('/signup/lp/self');
  const onBackToCompanyInfo = () => navigateToStep('company-info');

  const onPurchaseResumeClick = () => setShowHiddenRightBlock(true);

  const onContractSigned = () => {
    navigate({
      pathname: '/authentication/access-selection',
      search: createSearchParams({ redirectTo: '/home' }).toString(),
    });
  };

  const { form: companyInfoForm } = useCompanyInfoForm();
  const { form: adminInfoForm, isLoading: adminInfoLoading } =
    useAdminInfoForm();
  const { form: contractForm } = useContractForm({
    onSuccess: onContractSigned,
  });

  useEffect(
    () => refToTop.current?.scrollIntoView({ behavior: 'smooth' }),
    [step],
  );

  const getStep = () => {
    switch (step) {
      case 'company-info':
        return {
          component: <CompanyInfo form={companyInfoForm} />,
          form: companyInfoForm,
          onBack: onCompanyInfoBack,
          index: 1,
        };
      case 'admin-info':
        return {
          component: <AdminInfo form={adminInfoForm} />,
          form: adminInfoForm,
          onBack: onBackToCompanyInfo,
          index: 2,
        };
      case 'commercial-conditions':
        return {
          component: <Contract form={contractForm} />,
          form: contractForm,
          button: {
            text: 'Concluir contratação',
            icon: 'IconCheck' as IconsProps['name'],
          },
          index: 3,
        };
      default:
        return {
          component: <Navigate to="/signup/company/company-info" replace />,
        };
    }
  };

  const stepProps = getStep();
  const isLoading = stepProps.form?.isSubmitting || adminInfoLoading;
  const progress = ((stepProps.index ?? 1) / 3) * 100;

  return (
    <MainContainer>
      <LeftBlock>
        <ScrollArea>
          <Header ref={refToTop}>
            <LogoFlash />
            {!showRightBlock && (
              <PillButton
                variant="pink"
                icon="IconShoppingCart"
                size="extra-small"
                type="primary"
                onClick={onPurchaseResumeClick}
                label="Resumo da compra"
              />
            )}
          </Header>
          <ProgressBar value={progress} />
          <Content>{stepProps.component}</Content>
        </ScrollArea>
        <Footer>
          <div>
            {stepProps.onBack && (
              <Button
                size="large"
                variant="secondary"
                loading={isLoading}
                onClick={stepProps.onBack}
              >
                <Icons name="IconArrowLeft" /> Voltar
              </Button>
            )}
          </div>
          <Button
            size="large"
            variant="primary"
            loading={isLoading}
            disabled={!stepProps.form?.isValid}
            onClick={stepProps.form?.submitForm}
          >
            {stepProps.button?.text || 'Continuar'}{' '}
            <Icons name={stepProps.button?.icon || 'IconArrowRight'} />
          </Button>
        </Footer>
      </LeftBlock>

      {showRightBlock && (
        <RightBlock>
          <CommercialConditions />
        </RightBlock>
      )}
      {!showRightBlock && (
        <>
          <AbsoluteOverlay
            $active={showHiddenRightBlock}
            onClick={() => setShowHiddenRightBlock(false)}
          />
          <HiddenRightBlock $active={showHiddenRightBlock}>
            <PillButton
              icon="IconX"
              variant="default"
              size="extra-small"
              onClick={() => setShowHiddenRightBlock(false)}
            />
            <CommercialConditions />
          </HiddenRightBlock>
        </>
      )}
    </MainContainer>
  );
};
