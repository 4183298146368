import { getFromSS, setInSS } from '@flash-tecnologia/hros-web-utility';
import { formatCompanySignupPhone, trackEvent, validateEmail } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { SelfSignupParams, useSelfSignup } from '../../hooks/use-self-signup';
import { useSearchParams } from 'react-router-dom';
import { useSelfSignupStepNavigate } from '../../hooks';

export const useAdminInfoForm = () => {
  const { navigateToStep } = useSelfSignupStepNavigate();
  const { companySelfSignUp, isLoading: signupLoading } = useSelfSignup();
  const [searchParams] = useSearchParams();

  const form = useFormik({
    initialValues,
    validationSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      trackEvent('self_signup_admin_info_continue_clicked');

      const selfSignupInfo = getFromSS('selfSignupInfo');

      if (!selfSignupInfo) {
        navigateToStep('company-info');
        return;
      }

      const company: SelfSignupParams['company'] = {
        registrationNumber: selfSignupInfo.company.registrationNumber,
        legalName: selfSignupInfo.company.legalName,
        name: selfSignupInfo.company.name,
        employeesQuantity: selfSignupInfo.company.employeesQuantity,
        address: {
          zipCode: selfSignupInfo.company.address.zipCode,
          street: selfSignupInfo.company.address.street,
          number: selfSignupInfo.company.address.number,
          complement: selfSignupInfo.company.address.complement || '',
          district: selfSignupInfo.company.address.district,
          city: selfSignupInfo.company.address.city,
          state: selfSignupInfo.company.address.state,
        },
      };

      const admin: SelfSignupParams['admin'] = {
        email: values.email,
        phone: formatCompanySignupPhone(values.phone),
        name: values.name,
        documentNumber: values.documentNumber.replace(/\D/g, ''),
      };

      const selfSignupToken = searchParams.get('self_signup_token') ?? '';
      const signupResult = await companySelfSignUp({
        company,
        admin,
        selfSignupToken,
      });

      if (signupResult) {
        setInSS({
          key: 'selfSignupInfo',
          value: {
            ...selfSignupInfo,
            employeeId: signupResult.employeeId,
            companyId: signupResult.companyId,
          },
        });
        navigateToStep('commercial-conditions', {
          contractId: signupResult.contractId,
        });
      }
    },
  });

  return { form, isLoading: form.isSubmitting || signupLoading };
};

const validationSchema = yup.object({
  name: yup.string().required('Por favor, digite o nome do administrador'),
  phone: yup
    .string()
    .min(16, 'O número deve conter 11 dígitos')
    .required('Por favor, digite o número de celular do administrador'),
  email: yup
    .string()
    .required('Por favor, digite o e-mail do administrador')
    .test(
      'is-valid-email',
      'Por favor, digite um e-mail válido.',
      (value) => !value || validateEmail(value),
    ),
});

export type AdminInfoFormValues = {
  email: string;
  name: string;
  documentNumber: string;
  phone: string;
};

const initialValues: AdminInfoFormValues = {
  email: '',
  name: '',
  documentNumber: '',
  phone: '',
};
