import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 3px;
  position: relative;
`;

export const Background = styled.div`
  width: 100%;
  height: 1px;
  top: 0.5px;
  left: 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.neutral[90]};
`;

export const Progress = styled.div<{ percent: number }>`
  width: ${({ percent }) => percent}%;
  height: 2px;
  top: 0;
  left: 0;
  position: absolute;
  background: ${({ theme }) => theme.colors.secondary[50]};
  transition: width 0.3s ease-in-out;
`;
