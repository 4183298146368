import { getFromSS, removeFromSS } from '@flash-tecnologia/hros-web-utility';
import { trackEvent } from '@utils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSignContract } from '@/pages/SignUp/hooks/use-sign-contract';
import { useSearchParams } from 'react-router-dom';

export const useContractForm = ({ onSuccess }: ContractFormProps) => {
  const [searchParams] = useSearchParams();
  const { signContract } = useSignContract();

  const form = useFormik({
    initialValues: { termsChecked: false },
    validationSchema,
    isInitialValid: false,
    validate: (values) => {
      if (!values.termsChecked) {
        return { termsChecked: true };
      }
    },
    onSubmit: async () => {
      trackEvent('self_signup_contract_continue_clicked');
      const selfSignupInfo = getFromSS('selfSignupInfo');
      const contractId =
        searchParams.get('contractId') || selfSignupInfo.contractId;

      await signContract({
        employeeId: selfSignupInfo.employeeId,
        contractId,
      });

      removeFromSS({ key: 'selfSignupInfo' });
      onSuccess();
    },
  });

  return { form };
};

const validationSchema = yup.object({
  termsChecked: yup.boolean().required(),
});

export type ContractFormValues = {
  termsChecked: boolean;
};

type ContractFormProps = {
  onSuccess: () => void;
};
