import {
  CircularProgress,
  Icons,
  LinkButton,
  PDFViewer,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import ContractEmptyStateSVG from '../../../assets/contract-empty-state.svg';
import { CenterContainer } from './styles';
import { Modal } from '@flash-tecnologia/flamingo-ui-modal';

interface ModalProps {
  open: boolean;
  url: string;
  onRetry: () => any;
  loading?: boolean;
  onClose?: () => any;
}

export const GeneralServicesTerm = ({
  loading = false,
  open,
  url,
  onRetry,
  onClose,
}: ModalProps) => {
  const showPdf = !!url && !loading;
  const showError = !url && !loading;

  return (
    <Modal.Root
      size="large"
      open={open}
      onOpenChange={(open) => !open && onClose!()}
    >
      <Modal.Content.Root>
        <Modal.Content.Title>
          Contrato de Prestação de Serviços
        </Modal.Content.Title>
        <Modal.Content.Description>
          Para finalizar sua contratação, você deve ler atentamente e aceitar as
          condições comerciais do Contrato de Prestação de Serviços da Flash.
        </Modal.Content.Description>

        {showPdf && (
          <PDFViewer
            width="100%"
            height="650px"
            src={url}
            options={{ showToolbar: true, fit: 'height', page: 1 }}
          />
        )}

        {loading && (
          <CenterContainer>
            <CircularProgress
              variant="indeterminate"
              size={64}
              style={{ marginBottom: 16, alignSelf: 'center' }}
            />
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Carregando contrato
            </Typography>
            <Typography variant="body3" style={{ marginBottom: 16 }}>
              Aguarde um momento, estamos gerando o seu contrato.
            </Typography>
          </CenterContainer>
        )}

        {showError && (
          <CenterContainer>
            <ContractEmptyStateSVG
              style={{ marginBottom: 16, alignSelf: 'center' }}
            />
            <Typography variant="body3" style={{ fontWeight: 700 }}>
              Falha ao carregar contrato
            </Typography>
            <Typography variant="body3" style={{ marginBottom: 16 }}>
              Não conseguimos gerar o seu contrato.
            </Typography>
            <LinkButton
              variant="neutral"
              onClick={onRetry}
              style={{ alignSelf: 'center' }}
            >
              Tentar novamente <Icons fill="none" name="IconRefresh" />
            </LinkButton>
          </CenterContainer>
        )}

        <Modal.Content.Footer>
          <LinkButton
            variant="default"
            onClick={onClose}
            style={{ alignSelf: 'center' }}
          >
            Fechar
          </LinkButton>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  );
};
