import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { Container } from './input-helper-text.styles';

interface InputHelperTextProps {
  style?: React.CSSProperties;
  children: React.ReactNode;
}

export const InputHelperText = ({ style, children }: InputHelperTextProps) => {
  return (
    <Container style={style}>
      <Icons
        color="var(--color-feedback-error-50)"
        size={16}
        name="IconAlertCircle"
        fill="none"
      />
      <Typography.Caption color="neutral.40" weight={600}>
        {children}
      </Typography.Caption>
    </Container>
  );
};
