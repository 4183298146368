import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelfSignupSteps } from '../types';

export const useSelfSignupStepNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToStep = (
    step: SelfSignupSteps,
    params: Record<string, string> = {},
  ) => {
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }

    navigate({
      pathname: `/signup/company/${step}`,
      search: searchParams.toString(),
    });
  };

  return { navigateToStep };
};
