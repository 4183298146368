import { useState, useEffect } from 'react';
import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  StepContainer,
  FieldsContainer,
  StyledInput,
  NumberAndComplementContainer,
  ComplementInput,
  NumberInput,
} from './company-info.styles';
import { FormikProps } from 'formik';
import { CompanyInfoFormValues } from './company-info.hooks';
import { trackPage } from '@/utils';
import { useSearchParams } from 'react-router-dom';
import { getFromSS } from '@flash-tecnologia/hros-web-utility';
import { useUpdateDealStage } from '@/pages/SignUp/hooks/use-update-deal-stage';
import { useGetCep } from '@/pages/SignUp/hooks/use-get-cep';
import { InputHelperText } from './components/InputHelperText';

type CompanyInfoProps = {
  form: FormikProps<CompanyInfoFormValues>;
};

export const CompanyInfo = ({ form }: CompanyInfoProps) => {
  const { getCep } = useGetCep();
  const { updateDealStage } = useUpdateDealStage();
  const [searchParams] = useSearchParams();

  const [showAddress, setShowAddress] = useState(false);
  const [isZipCodeInvalid, setIsZipCodeInvalid] = useState(false);

  const getZipCode = async (zipCode: string) => {
    const zipCodeData = await getCep(zipCode);

    setIsZipCodeInvalid(!zipCodeData?.state);
    setShowAddress(!!zipCodeData?.state);

    if (zipCodeData?.state) {
      form.setFieldValue('street', zipCodeData.street);
      form.setFieldValue('complement', zipCodeData.complement || '');
      form.setFieldValue('district', zipCodeData.district);
      form.setFieldValue('state', zipCodeData.state);
      form.setFieldValue('city', zipCodeData.city);
    }
  };

  const clearAddressFields = () => {
    form.setFieldValue('street', '');
    form.setFieldValue('number', '');
    form.setFieldValue('complement', '');
    form.setFieldValue('district', '');
    form.setFieldValue('state', '');
    form.setFieldValue('city', '');
  };

  useEffect(() => {
    trackPage('self_signup_company_info_page');

    const selfSignupToken = searchParams.get('self_signup_token');
    if (selfSignupToken) {
      updateDealStage({ trackingId: selfSignupToken, stage: 'pending-signup' });
    }
  }, []);

  useEffect(() => {
    const selfSignupInfo = getFromSS('selfSignupInfo');
    if (!selfSignupInfo) return;

    const zipCode = selfSignupInfo.company.address.zipCode;
    form.setValues({
      registrationNumber: selfSignupInfo.company.registrationNumber,
      legalName: selfSignupInfo.company.legalName,
      name: selfSignupInfo.company.name,
      employeesQuantity: selfSignupInfo.company.employeesQuantity,
      zipCode: zipCode.slice(0, 5) + '-' + zipCode.slice(5),
      street: selfSignupInfo.company.address.street,
      number: selfSignupInfo.company.address.number,
      complement: selfSignupInfo.company.address.complement,
      district: selfSignupInfo.company.address.district,
      state: selfSignupInfo.company.address.state,
      city: selfSignupInfo.company.address.city,
    });
    setShowAddress(true);
  }, []);

  useEffect(() => {
    const parsedZipCode = form.values.zipCode?.replace(/[^\d]+/g, '');

    if (parsedZipCode?.length === 8) {
      getZipCode(parsedZipCode);
      return;
    }

    setShowAddress(false);
    setIsZipCodeInvalid(false);
    clearAddressFields();
  }, [form.values.zipCode]);

  return (
    <StepContainer>
      <Typography.Headline7 color="neutral.30">
        Para começarmos a contratação,
        <br /> preencha os dados da sua empresa
      </Typography.Headline7>
      <FieldsContainer>
        <StyledInput
          required
          id="registrationNumber"
          name="registrationNumber"
          label="CNPJ"
          value={form.values.registrationNumber}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          imaskProps={{ mask: '00.000.000/0000-00' }}
          error={
            form.touched.registrationNumber &&
            Boolean(form.errors.registrationNumber)
          }
        />
        {form.touched.registrationNumber && form.errors.registrationNumber && (
          <InputHelperText>{form.errors.registrationNumber}</InputHelperText>
        )}

        <StyledInput
          required
          id="legalName"
          name="legalName"
          label="Razão Social"
          autoComplete="off"
          value={form.values.legalName}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.legalName && Boolean(form.errors.legalName)}
        />
        {form.touched.legalName && form.errors.legalName && (
          <InputHelperText>{form.errors.legalName}</InputHelperText>
        )}

        <StyledInput
          id="name"
          name="name"
          label="Nome fantasia (Opcional)"
          value={form.values.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={form.touched.name && Boolean(form.errors.name)}
        />
        {form.touched.name && form.errors.name && (
          <InputHelperText>{form.errors.name}</InputHelperText>
        )}

        <StyledInput
          required
          id="employeesQuantity"
          name="employeesQuantity"
          label="Quantidade de funcionários"
          value={form.values.employeesQuantity}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            form.touched.employeesQuantity &&
            Boolean(form.errors.employeesQuantity)
          }
        />
        {!(form.touched.employeesQuantity && form.errors.employeesQuantity) && (
          <Typography.Caption color="neutral.50" style={{ marginLeft: 16 }}>
            Não exigimos um número mínimo de funcionários para utilização dos
            produtos
          </Typography.Caption>
        )}
        {form.touched.employeesQuantity && form.errors.employeesQuantity && (
          <InputHelperText>{form.errors.employeesQuantity}</InputHelperText>
        )}

        <StyledInput
          required
          id="zipCode"
          name="zipCode"
          label="CEP"
          value={form.values.zipCode}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            (form.touched.zipCode && Boolean(form.errors.zipCode)) ||
            isZipCodeInvalid
          }
          imaskProps={{ mask: '00000-000' }}
        />
        {form.touched.zipCode && form.errors.zipCode && (
          <InputHelperText>{form.errors.zipCode}</InputHelperText>
        )}
        {isZipCodeInvalid && (
          <InputHelperText>Por favor, digite um CEP válido.</InputHelperText>
        )}
        {showAddress && (
          <>
            <StyledInput
              required
              id="street"
              name="street"
              label="Logradouro"
              value={form.values.street}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.street && Boolean(form.errors.street)}
              autoComplete="nope"
            />
            {form.touched.street && form.errors.street && (
              <InputHelperText>{form.errors.street}</InputHelperText>
            )}

            <NumberAndComplementContainer>
              <NumberInput
                required
                id="number"
                name="number"
                label="Número"
                value={form.values.number}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.number && Boolean(form.errors.number)}
              />
              {form.touched.number && form.errors.number && (
                <InputHelperText style={{ order: 3 }}>
                  {form.errors.number}
                </InputHelperText>
              )}

              <ComplementInput
                id="complement"
                name="complement"
                label="Complemento (Opcional)"
                value={form.values.complement}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
            </NumberAndComplementContainer>

            <StyledInput
              required
              id="district"
              name="district"
              label="Bairro"
              value={form.values.district}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.district && Boolean(form.errors.district)}
              autoComplete="nope"
            />
            {form.touched.district && form.errors.district && (
              <InputHelperText>{form.errors.district}</InputHelperText>
            )}

            <StyledInput
              required
              id="city"
              name="city"
              label="Cidade"
              value={form.values.city}
              disabled
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              error={form.touched.city && Boolean(form.errors.city)}
              autoComplete="nope"
            />
            {form.touched.city && form.errors.city && (
              <InputHelperText>{form.errors.city}</InputHelperText>
            )}

            <StyledInput
              required
              id="state"
              name="state"
              label="Estado"
              value={form.values.state}
              disabled
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              inputProps={{ maxLength: 2 }}
              imaskProps={{ mask: /^[A-Za-z]+$/ }}
              error={form.touched.state && Boolean(form.errors.state)}
              autoComplete="nope"
            />
            {form.touched.state && form.errors.state && (
              <InputHelperText>{form.errors.state}</InputHelperText>
            )}
          </>
        )}
      </FieldsContainer>
    </StepContainer>
  );
};
