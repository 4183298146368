import { Button as DsButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const Button = styled(DsButton)`
  &.flamingo-ui-button {
    &.MuiPaper-root.MuiButtonBase-root {
      &.size-small {
        min-width: 128px;
        font-size: 12px;
        padding: 8px;
      }

      &.size-medium {
        min-width: 160px;
        font-size: 14px;
        padding: 8px 16px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &.size-large {
        min-width: 184px;
        font-size: 16px;
        padding: 12px 16px;
      }
    }

    &-default {
      &--secondary {
        &.MuiPaper-root.MuiButtonBase-root {
          border-color: ${({ theme }) => theme.colors.neutral[90]};

          &:active {
            background: transparent;
            border-color: ${({ theme }) => theme.colors.secondary[70]};
            color: ${({ theme }) => theme.colors.secondary[50]};
          }

          &:focus-visible {
            outline: 4px solid ${({ theme }) => theme.colors.secondary[80]};
            border-color: ${({ theme }) => theme.colors.secondary[70]};
          }

          &.loading {
            border-color: ${({ theme }) => theme.colors.secondary[70]};
          }
        }
      }
    }
  }
`;
