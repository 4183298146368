import { setInSS, removeFromSS } from '@flash-hros/utility';
import { dispatchToast, formatCompanySignupPhone } from '../../../utils';
import { trpc } from '@api/client';

export const useCompanySignupMutation = () => {
  const { mutateAsync, isLoading } = trpc.companySignup.useMutation({
    trpc: { context: { useCognitoToken: true } },
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ops! Não foi possível cadastrar sua empresa no momento. Tente novamente mais tarde ou fale com nosso suporte.',
      });
    },
  });

  const companySignUp = async ({ company, admin, dealId, leadId }) => {
    if (isLoading) return;

    const response = await mutateAsync({
      employee: admin,
      company,
      dealId,
      leadId,
    });
    return response;
  };

  return { companySignUp, isLoading };
};
