import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const RightBlock = styled.div`
  width: max(70%, 564px);
  height: 100%;
  padding: 44px 80px;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  background-image: radial-gradient(
      circle at top right,
      ${({ theme }) => theme.colors.secondary[99]} 18%,
      transparent 40%
    ),
    radial-gradient(
      circle at bottom left,
      ${({ theme }) => theme.colors.secondary[99]} 10%,
      transparent 45%
    );
  border-left: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  overflow-y: auto;

  @media screen and (max-width: 1365px) {
    padding: 44px 3%;
  }
`;

export const HiddenRightBlock = styled(RightBlock)<{
  $active: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  position: absolute;
  right: ${({ $active }) => ($active ? '0px' : '-400px')};
  width: 100%;
  max-width: 400px;
  z-index: 998;
  transition: right 0.35s ease-in;
  padding: 20px 3%;
`;

export const AbsoluteOverlay = styled.div<{
  $active: boolean;
}>`
  position: absolute;
  z-index: 997;
  width: 100%;
  height: 100vh;
  background: ${({ $active }) =>
    $active ? 'rgba(0, 0, 0, 0.3)' : 'transparent'};
  display: ${({ $active }) => ($active ? 'flex' : 'none')};
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.35s ease-in;
`;

export const ScrollArea = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacings.xl3};
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  padding: 40px 30px 10px;
  padding-bottom: ${({ theme }) => theme.spacings.m};

  @media screen and (max-width: 1000px) {
    gap: ${({ theme }) => theme.spacings.xl5};
  }

  @media screen and (max-width: 780px) {
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    padding: 30px 3%;
  }

  @media screen and (min-width: 781px) {
    overflow: auto;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.xs};
  padding: ${({ theme }) => `${theme.spacings.xs2} ${theme.spacings.xs}`};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral[90]};

  @media screen and (max-width: 520px) {
    align-content: center;
    flex-direction: column-reverse;
  }
`;
