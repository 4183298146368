import { Background, Container, Progress } from './progress-bar.styles';

interface ProgressBarProps {
  value: number;
}

export const ProgressBar = ({ value }: ProgressBarProps) => {
  return (
    <Container
      role="progressbar"
      aria-valuenow={value}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <Background />
      <Progress percent={value} />
    </Container>
  );
};
