import { dispatchToast } from '../../../utils';
import { trpc } from '@api/client';

export const useCompanySelfSignupMutation = () => {
  const { mutateAsync, isLoading } = trpc.companySelfSignup.useMutation({
    trpc: { context: { useCognitoToken: true } },
    onError: () => {
      dispatchToast({
        type: 'error',
        content:
          'Ops! Não foi possível cadastrar sua empresa no momento. Tente novamente mais tarde ou fale com nosso suporte.',
      });
    },
  });

  const companySelfSignUp = async ({ company, admin, selfSignupToken }) => {
    if (isLoading) return;

    const response = await mutateAsync({
      company,
      employee: admin,
      selfSignupToken: selfSignupToken || '',
    });
    return response;
  };

  return { companySelfSignUp, isLoading };
};
